/* Set default font-family */
.ql-container {
  background-color: white;
}
.app .ql-editor {
  font-family: "Lato";
}

/* Set dropdown font-families */
.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Noto-Sans-JP"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Noto-Sans-JP"]::before {
  font-family: "Noto Sans JP", cursive;
  content: "Noto Sans JP" !important;
}
/* Set content font-families */
.ql-font-Lato {
  font-family: "Lato" !important;
}
.ql-font-Roboto {
  font-family: "Roboto" !important;
}
.ql-font-Noto-Sans-JP {
  font-family: "Noto Sans JP" !important;
}
.ql-tooltip {
  left: 0 !important;
}

.ql-snow {
  .ql-picker {
    .ql-picker-options {
      overflow: auto;
      height: 300px;
    }
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

body {
  font-family:
    Lato,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto;
  font-size: 14px;
}

.bg_layout_content {
  background-color: #f8fafd;
}
.ant-notification-notice {
  padding: 16px !important;
  width: 100% !important;
}
.ant-notification-notice-close {
  top: 15px !important;
  inset-inline-end: 10px !important;
}
.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
.ant-notification-notice-wrapper {
  width: calc(100vw - 720px) !important;
  border-radius: 2px !important;
  margin-right: 400px;
}
.ant-notification-topRight {
  right: -24px !important;
}
.ant-notification-notice-warning {
  background-color: #fffbe6;
  border: #ffe58f !important;
}
.ant-notification-notice-success {
  background-color: #f6ffed;
  border: #b7eb8f !important;
}
.ant-notification-notice-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7 !important;
}

.shadow_antd .ant-btn {
  justify-content: left;
  text-align: start;
}
.ant-form-item-label {
  color: #323842;
  font-weight: 700;
}
.ant-modal-content .ant-modal-header {
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 10px !important;
}
.ant-modal-title {
  font-size: 20px !important;
}
.address-input input,
.address-input .ant-select {
  margin-bottom: 15px !important;
}

.modal_confirm {
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-confirm-body {
      padding: 16px;
      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }
    .ant-modal-confirm-btns {
      padding: 16px;
      text-align: center;
      .ant-btn {
        width: 45%;
      }
      .ant-btn-primary {
        background-color: #f87171;
        &:hover {
          background-color: #f87171;
        }
      }
    }
  }
}
.modal_confirm_success {
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-confirm-body {
      padding: 16px;
      .ant-modal-confirm-paragraph {
        max-width: 100%;
      }
    }
    .ant-modal-confirm-btns {
      padding: 16px;
      text-align: center;
      .ant-btn {
        width: 45%;
      }
    }
  }
}

.ant-select-dropdown .ant-select-item {
  padding: 8px 12px !important;
}

.ant-input-status-error {
  border-color: #ff4d4f !important;
}

.ant-form-item-explain {
  margin-top: 3px;
  .ant-form-item-explain-error {
    background-image: url("../src/assets/images/close-circle.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: left center;
    padding-left: 20px;
    padding-top: 1px;
    font-size: 12px;
    font-weight: 400;
    color: #646970 !important;
  }
}

.box__phone {
  .ant-input-group-addon {
      background-color: white !important;
  }
}

