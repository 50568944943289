@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/theme/theme.css");
@layer components {
  .wapper_small {
    @apply max-w-screen-lg m-auto;
  }

  .__content {
    @apply p-4 bg-white rounded shadow;
  }

  .footer_modal {
    padding-top: 10px;
    @apply flex justify-end gap-4 border-t;
  }

  .shadow_antd {
    @apply bg-white cursor-pointer rounded p-2 space-y-2;
    box-shadow:
      0 3px 6px -4px #0000001f,
      0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
  }
  .show__sider {
    flex: 0 0 260px;
    max-width: 260px;
    min-width: 260px;
    width: 260px;
  }
  .hide__sider {
    flex: 0 0 88px;
    max-width: 88px;
    min-width: 88px;
    width: 88px;
  }
  .text-filter {
    @apply text-dark-400 mb-[2px] text-sm;
  }
  .menu__item {
    @apply rounded h-11 px-4 flex items-center gap-4 ease-in-out duration-200;
  }
}

.w-btn {
  width: 200px;
}

/* tabs__custom */
.tabs__custom .ant-tabs-nav {
  margin: 0px !important;
}
.tabs__custom .ant-tabs-nav::before {
  border-bottom: none !important;
}
.tabs__custom .ant-tabs-tab {
  background-color: var(--color-primary-50) !important;
  margin-left: 0px !important;
  border: none !important;
  border-radius: 12px 12px 0px 0px !important;
  box-shadow:
    0px 9px 28px 8px #00000005,
    0px 3px 6px -4px #0000000f,
    0px 6px 16px 0px #0000000a;
}
.tabs__custom .ant-tabs-tab .ant-tabs-tab-btn {
  @apply text-primary-300;
}
.tabs__custom .ant-tabs-tab-active {
   background-color: white !important;
  box-shadow: 4px 6px 16px 0px #0000000a;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}
.tabs__custom .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-primary-700;
}

/* __approval_seting_form */
.__approval_seting_form .ant-select-selection-item {
  background-color: var(--color-primary-100) !important;
}
.__approval_seting_form .ant-select-selection-item .ant-select-selection-item-remove {
  color: var(--color-primary-700) !important;
}
