@media only screen and (max-width: 640px) {
  .img-background {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
  .container-login {
    top: 10%;
  }
}

@media only screen and (max-height: 700px) {
  .img__bg {
    min-height: 780px !important;
  }
}

.__login {
  .ant-input {
    border-radius: 8px;
  }
  .ant-input-affix-wrapper {
    border-radius: 8px;
  }
}
.__login {
  background-image: url("https://edg-dev-edg-upload.s3.ap-northeast-1.amazonaws.com/images/6edb24e7-6d3d-4052-8426-677be1c23f60.png");
  background-size: cover;
}
